import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Add, DeleteForever, List } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FORMAT_NATURAL, convertTimestamp } from 'common/util/commonUtil';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import { TABULAR_VIEWS, FILE_UPLOAD_PROPS, CENSUS_DELETE_CONFIRMATION_MSG, PRIMARY } from 'common/Constants';
import getDataForTabularViews from 'common/service';
import FileUploadDialog from 'common/components/FileUploadDialog';
import { saveCensusFile } from 'features/evaluation/state/service';
import StudiesListDialog from 'features/evaluation/component/studiesListDialog';
import store from 'app/store';
import { confirmDialog } from 'common/components/ConfirmationDialog';
import { deleteCensusById } from './state/service';
import CensusDetailDialog from './CensusDetailDialog';

export default function CensusFiles() {
  const tableRef = useRef();
  const userId = useSelector((state) => state.auth.userId);
  const [studiesListId, setStudiesListId] = useState(null);
  const refreshFlag = useSelector((state) => state.census.refreshFlag);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  useEffect(() => {
    refresh();
  }, [refreshFlag]);

  const handleListCensusStudies = (id) => {
    setStudiesListId(id);
  };

  const handleCensusDelete = (id, name) => {
    const message = `${CENSUS_DELETE_CONFIRMATION_MSG} '${name}'?`;
    confirmDialog(() => store.dispatch(deleteCensusById(id)), message);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(undefined);
  const handleRowClick = (rowData) => {
    setData(rowData);
    setDialogOpen(true);
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '30%',
      cellStyle: {
        width: '30%',
        overflowWrap: 'anywhere',
      },
      render: (rowData) => (
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outlineColor: PRIMARY,
            cursor: 'pointer',
            display: 'inline',
            margin: 0,
            padding: 0,
            fontSize: '1rem',
            overflowWrap: 'anywhere',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: PRIMARY,
          }}
          onClick={() => {
            handleRowClick(rowData);
          }}
        >
          {rowData.name}
        </button>
      ),
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      width: '17%',
      cellStyle: { width: '17%' },
      render: (rowData) => convertTimestamp(rowData.updatedAt),
    },
    {
      title: 'Zip Code Count',
      field: 'zipCodeCount',
      width: '8%',
      alignRight: true,
      headerStyle: { whiteSpace: 'nowrap' },
      cellStyle: { width: '8%', paddingRight: '3%' },
      render: (rowData) => FORMAT_NATURAL(rowData.zipCodeCount),
    },
    {
      title: 'MSA Count',
      field: 'msaCount',
      width: '2%',
      alignRight: true,
      headerStyle: { whiteSpace: 'nowrap' },
      cellStyle: { width: '2%', paddingRight: '3%' },
      render: (rowData) => FORMAT_NATURAL(rowData.msaCount),
    },
    {
      title: 'Total Population',
      field: 'totalPopulation',
      width: '10%',
      alignRight: true,
      headerStyle: { whiteSpace: 'nowrap' },
      cellStyle: { width: '10%', paddingRight: '3%' },
      render: (rowData) => FORMAT_NATURAL(rowData.totalPopulation),
    },
    {
      title: 'Studies',
      field: 'noOfStudies',
      width: '2%',
      alignRight: true,
      cellStyle: { width: '2%', paddingRight: '3%' },
      render: (rowData) => FORMAT_NATURAL(rowData.noOfStudies),
    },
    {
      title: 'Uploaded By',
      field: 'uploadedBy',
      width: '20%',
      cellStyle: {
        width: '20%',
      },
    },
  ];

  return (
    <>
      <CensusDetailDialog data={data} handleClose={() => setDialogOpen(false)} open={dialogOpen} />
      <CustomizedMaterialTable
        title={TABULAR_VIEWS().census.tableTitle}
        tableRef={tableRef}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
        columns={columns}
        data={(query) => getDataForTabularViews('census', columns, query)}
        actions={[
          (rowInfo) => ({
            icon: () => <List />,
            tooltip: 'List Studies',
            disabled: !rowInfo.noOfStudies,
            onClick: (_, rowData) => {
              handleListCensusStudies(rowData?.id);
            },
          }),
          (rowInfo) => ({
            icon: () => <DeleteForever />,
            tooltip: 'Delete',
            disabled: (!!rowInfo.noOfStudies) || rowInfo.createdBy !== userId,
            onClick: (_, rowData) => {
              handleCensusDelete(rowData?.id, rowData?.name);
            },
          }),
          {
            icon: () => <Add />,
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => setIsUploadDialogOpen(true),
          },
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: refresh,
          },
        ]}
    />
      <FileUploadDialog
        open={isUploadDialogOpen}
        onClose={() => {
          setIsUploadDialogOpen(false);
        }}
        dialogDisplayInfo={FILE_UPLOAD_PROPS.census}
        saveFileReduxFunction={saveCensusFile}
      />
      {studiesListId && (
      <StudiesListDialog
        id={studiesListId}
        handleClose={() => {
          setStudiesListId(null);
        }}
        viewName="census"
      />
      )}
    </>
  );
}
