import {
  Grid,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormAutocompleteTextField from 'common/components/FormAutocompleteTextField';
import * as Constants from 'common/Constants';
import { EVALTYPE } from 'common/Constants';
import store from 'app/store';
import { getCensusList, getStatesForCensus } from 'features/census/state/service';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { useNavigate, useLocation } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import NavigateAwayWarningDialog from 'common/components/navigateAwayWarningDialog';
import useCallbackPrompt from 'common/components/useCallbackPrompt';
import { clearInlinePageError } from 'app/state/reducer';
import EvaluationName from '../../EvaluationName';
import InputHeader from '../../InputHeader';
import EvaluationAction from '../../EvaluationAction';
import { save } from '../../../state/service';
import CensusStatsAndUpload from './CensusStatsAndUpload';
import { setPrevVisitState } from '../../../state/reducer';

export default function CreatePopulationStudy() {
  const location = useLocation();
  const evaluation = useSelector((state) => state.evaluation.evaluation);
  const { id, createdAt, updatedAt, createdBy, updatedBy, privateEvaluation, name, status,
    ...cloneEvaluation } = evaluation;
  const [cloneEval] = useState(location.state?.isCLone ? cloneEvaluation : {});
  const methods = useForm({ defaultValues: { ...cloneEval, census: null, compareNetworksPT: 'ALL', compareNetworks: [] } });
  const { control, formState, setValue, watch, handleSubmit } = methods;
  const censusList = useSelector((state) => state.census.censusList);
  const planTypeOptions = useSelector((state) => state.provider.planTypes);
  const networks = useSelector((state) => state.provider.networks);
  const [censusStates, setCensusStates] = useState([]);
  const [disableNetworkSelection, setdisableNetworkSelection] = useState(true);
  const [disableNetworkOptions, setDisableNetworkOptions] = useState(false);
  const [cloneProcessed, setCloneProcessed] = useState(false);
  const [evalType] = useState(EVALTYPE.POPULATION);
  const navigate = useNavigate();
  const [block, setBlock] = useState(true);
  const blockNavigation = formState.isDirty && block;
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(blockNavigation);
  const [clonedCompareNetwork, setClonedCompareNetwork] = useState(false);
  const panVersion = useSelector((state) => state.app.panoramaVersion);

  const watchCensus = watch('census');
  const compareNetworksPT = watch('compareNetworksPT');
  const watchCompareNetworks = watch('compareNetworks');
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const openCensusStatsAndUpload = useCallback((open) => {
    store.dispatch(clearInlinePageError());
    setUploadDialogOpen(open);
  }, []);
  const networkOptions = useMemo(() => (
    networks.filter((network) => {
      if ((compareNetworksPT === 'ALL' || !compareNetworksPT) || network.planType === compareNetworksPT) {
        return network.allState || network.states.some((state) => censusStates.includes(state));
      }
      return false;
    })
  ), [networks, compareNetworksPT, censusStates]);

  useEffect(() => {
    store.dispatch(getCensusList());
    store.dispatch(clearInlinePageError());
  }, []);

  useEffect(() => {
    if (cloneProcessed) {
      if (!clonedCompareNetwork) {
        setValue('compareNetworks', []);
      }
      const censusId = watchCensus.id;
      if (censusId) {
        setdisableNetworkSelection(false);
        setDisableNetworkOptions(false);
        getStatesForCensus(censusId)
          .then((statesList) => setCensusStates(statesList));
      } else setdisableNetworkSelection(true);
    }
  }, [watchCensus]);

  useEffect(() => {
    // check if cloned census is in user's census list
    const censusExists = censusList.some((obj) => obj.id === cloneEval.census?.id);
    if (censusExists && Object.keys(cloneEval).length !== 0) {
      setdisableNetworkSelection(false);
      setValue('census', cloneEval.census, { shouldDirty: true });
      const clonedNetworks = cloneEval.compareNetworks?.map((e) => e.network);
      setValue('compareNetworks', clonedNetworks, { shouldDirty: true });
      if (clonedNetworks) {
        setClonedCompareNetwork(true);
      }
    }
    setCloneProcessed(true);
  }, [cloneEval, censusList]);

  useEffect(() => {
    if (watchCompareNetworks.length >= 4) {
      setDisableNetworkOptions(true);
    }
  }, [watchCompareNetworks]);

  const saveAndProcess = (data) => {
    const compareNetworks = data.compareNetworks.map((nw) => ({ networkCode: nw.code }));
    store.dispatch(
      save({
        ...data,
        compareNetworks,
        evalType,
        version: Constants.STUDY_VERSION_ONE,
        panoramaVersion: panVersion,
      }),
    ).unwrap().then(() => {
      setBlock(false);
      confirmNavigation();
      store.dispatch(setPrevVisitState(null));
      navigate(RoutePath.app.evaluation.path);
    });
  };

  return (
    <Grid container>
      <Grid container item spacing={2} marginLeft={-0.5} xs={12} md={9} lg={7} xl={6}>
        <Grid item xs={12} sx={{ paddingTop: '0px' }}>
          <Typography variant="h1">
            Create Population Study
          </Typography>
        </Grid>
        <FormProvider {...methods}>
          <InputHeader title="Define Your Member Population" />
          <Grid item xs={12}>
            <Typography variant="body1">
              Upload a new census summary file or select an existing census summary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormAutocompleteTextField
              fieldName="census"
              fieldRules={{ required: THIS_FIELD_IS_REQUIRED }}
              autocompleteProps={{
                id: 'fileNameDropdown',
                options: censusList,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (option, value) => option.id === value?.id,
                value: censusList.find((option) => option.id === watchCensus?.id) || null,
                disableClearable: true,
              }}
              textFieldProps={{
                label: 'Census Summary Name',
                required: true,
                helperText: formState.errors.census?.message,
              }}
              isError={formState.errors.census}
            />
          </Grid>
          <CensusStatsAndUpload
            censusObj={watchCensus}
            uploadDialogOpen={uploadDialogOpen}
            setUploadDialogOpen={openCensusStatsAndUpload} />
          <InputHeader title="Select Networks to Compare" />
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={3} md={3} lg={2.5} xl={2}>
              <FormAutocompleteTextField
                fieldName="compareNetworksPT"
                autocompleteProps={{
                  id: 'compareNetworksPTFilter',
                  options: planTypeOptions,
                  disableClearable: true,
                  disabled: disableNetworkSelection,
                }}
                textFieldProps={{
                  label: 'Plan Type',
                }}
                tooltipText={Constants.TOOLTIP_MSG().PLAN_TYPE}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9.5} xl={10}>
              <FormAutocompleteTextField
                fieldName="compareNetworks"
                fieldRules={{ required: THIS_FIELD_IS_REQUIRED }}
                onValueChange={(data) => setDisableNetworkOptions(data.length >= 4)}
                autocompleteProps={{
                  multiple: true,
                  id: 'compareNetworksFilter',
                  options: networkOptions,
                  isOptionEqualToValue: (option, value) => option.code === value?.code,
                  getOptionDisabled: () => disableNetworkOptions,
                  filterSelectedOptions: true,
                  disabled: disableNetworkSelection,
                }}
                textFieldProps={{
                  label: 'Select up to four Networks',
                  required: true,
                  helperText: !disableNetworkSelection
                    && formState.errors.compareNetworks?.message,
                }}
                tooltipText={Constants.TOOLTIP_MSG().NETWORK_RATES_TO_MEDICARE}
                isError={!disableNetworkSelection && formState.errors.compareNetworks}
              />
            </Grid>
          </Grid>
          <EvaluationName control={control} sizeXS={12} />
          <EvaluationAction
            onClick={handleSubmit(saveAndProcess)}
            sizeXS={12}
            isPopUpOpen={uploadDialogOpen}
          />
        </FormProvider>
      </Grid>
      <NavigateAwayWarningDialog
        open={(showPrompt && blockNavigation)}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
        content={{
          title: Constants.NAVIGATION_AWAY_WARNING_TITLE_MSG,
          body: Constants.NAVIGATION_AWAY_WARNING_BODY_MSG,
          confirm: Constants.NAVIGATION_AWAY_CONFIRM_BUTTON,
          cancel: Constants.NAVIGATION_AWAY_CANCEL_BUTTON,
        }}
      />
    </Grid>
  );
}
